var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dP95{background:url(" + escape(require("./wave_footer_centered.svg")) + ") repeat-x bottom;color:#fefefe;min-height:152px}._26pKB{padding-left:16px;padding:120px 1rem .5rem;padding-right:16px;padding-bottom:8px;text-align:center}@media screen and (min-width:768px){._26pKB{margin-left:auto;margin-right:auto;max-width:1180px}}.tTplm{color:#a3b8cc;font-size:10.72px;font-size:.67rem;margin:0}.tTplm span{margin:0 8px;margin:0 .5rem}.tTplm span:first-child{display:block}@media screen and (min-width:768px){.tTplm span:first-child{display:inline}}.tTplm a{color:#dae6f2;text-decoration:none}", ""]);

// exports
exports.locals = {
	"root": "_3dP95",
	"container": "_26pKB",
	"text": "tTplm"
};