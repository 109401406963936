import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import YouTube from 'react-youtube';
import Modal from 'react-modal';
import s from './Home.scss';
import Link from '../../components/Link';
import teaserUrl from './video-teaser.jpg';

Modal.setAppElement('#app');

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal = event => {
    event.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const opts = {
      height: '315',
      width: '560',
      playerVars: {
        suggestedQuality: 'hd1080',
        showinfo: 0,
        rel: 0,
        iv_load_policy: 0,
        autoplay: 1,
      },
    };

    return (
      <div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className={s.Modal}
          overlayClassName={s.Overlay}
        >
          <YouTube videoId="7fQcDDF0f4c" opts={opts} />
          <button
            title="Close (Esc)"
            onClick={this.closeModal}
            className={s.closeModalButton}
          >
            ×
          </button>
        </Modal>
        <section className={s.hero}>
          <div>
            <h1 className={s.hero__title}>
              Water and sanitation are a human right!
            </h1>
            <h2 className={s.hero__subtitle}>
              Right to water is an European citizens initiative launched to
              ensure that water remains a public service and a public good.
            </h2>
          </div>
          <div className={s.hero__right}>
            <Link
              onClick={e => {
                this.openModal(e);
              }}
              className={s.videoTeaser}
              to="https://www.youtube.com/watch?v=7fQcDDF0f4c"
            >
              <span>
                <span className={s.playButton}>
                  <svg viewBox="0 0 48 48">
                    <g fill="none" fillRule="evenodd">
                      <circle cx="24" cy="24" r="24" fill="#EA5F34" />
                      <path
                        fill="#FFF"
                        stroke="#FFF"
                        strokeWidth="2"
                        d="M20,15.8594465 L20,31.4972494 L31.8320118,23.6783479 L20,15.8594465 Z"
                      />
                    </g>
                  </svg>
                </span>
                <span className={s.videoText}>Watch the 2min video</span>
              </span>
              <svg width="310" height="319" viewBox="0 0 310 319">
                <defs>
                  <mask id="teaserMask">
                    <path
                      fill="#ffffff"
                      d="M139.227245,313.983767 C159.575169,316.218464 183.021956,317.887185 205.999238,315.106556 C223.903082,312.939896 241.521867,308.071818 257.167433,298.665139 C267.18029,292.645048 276.384967,284.766059 284.338963,274.54661 C293.327086,262.998494 300.718176,248.461774 305.873729,230.24158 C309.064428,218.96536 310.452223,207.073753 310.357827,194.877797 C310.252278,181.240721 308.29356,167.22312 304.93004,153.259832 C299.380565,130.221792 290.006977,107.331601 278.823026,86.5422596 C272.633642,75.0370932 265.889799,64.1753534 258.932818,54.2880633 C250.801795,42.7322224 242.379627,32.5074733 234.211236,24.1422991 C220.724863,10.3310288 207.930273,1.5888598 198.279979,0.294313905 C186.403557,-1.29885754 165.463899,3.61709696 141.522034,14.043965 C130.876582,18.6801419 119.637587,24.4058405 108.337848,31.1333118 C97.0134486,37.8754658 85.6280404,45.62377 74.7179208,54.2898998 C60.734983,65.3968316 47.5327651,78.0114046 36.2403141,91.9476722 C26.8714544,103.509993 18.8171456,115.982076 12.7221619,129.257732 C7.25394208,141.168215 3.36280086,153.725502 1.51435421,166.852908 C-2.53257952,195.593657 1.7914431,219.213468 11.3882813,238.479163 C19.0788846,253.918083 30.1556447,266.560827 43.0241594,276.802026 C55.9973459,287.126526 70.7915535,295.010255 85.7731566,300.85755 C104.33985,308.1041 123.194358,312.222966 139.227245,313.983767 Z"
                    />
                  </mask>
                </defs>
                <path
                  fill="#5CB6E4"
                  d="M235.640625,245.056641 C256.025966,254.483204 319.008948,286.796458 335.531252,220.985479 C352.053555,155.174499 317.286216,100.590071 286.329475,94.0372548 C255.372734,87.484439 28.9511719,153.892578 63.7832031,220.985479 C98.6152344,288.078379 215.255284,235.630077 235.640625,245.056641 Z"
                  transform="translate(199.970591, 177.453269) rotate(-90.000000) translate(-199.970591, -177.453269)"
                />
                <image
                  mask="url(#teaserMask)"
                  xlinkHref={teaserUrl}
                  preserveAspectRatio="xMidYMid slice"
                  width="310"
                  height="319"
                />
              </svg>
            </Link>
          </div>
        </section>
      </div>
    );
  }
}

export default withStyles(s)(Home);
