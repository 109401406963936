exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Gxun{margin-top:16px;margin-top:1rem}@media screen and (min-width:768px){._2Gxun{margin:1rem 5rem 0}}._2AvjS{padding:0;margin:0}@media screen and (min-width:768px){._1Jivn{display:inline-block;padding:0 .85em}}", ""]);

// exports
exports.locals = {
	"root": "_2Gxun",
	"navRoot": "_2AvjS",
	"navItem": "_1Jivn"
};