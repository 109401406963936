// In src/FilterableDocumentList.js

import React from 'react';
import PropTypes from 'prop-types';
import Prismic from 'prismic-javascript';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './FilterableDocumentList.scss';
import Documents from '../Documents';
import Loader from '../Loader';
import Link from '../Link';
import SearchBar from '../SearchBar';
import WaveLightBottom from './WaveLightBottom';

class FilterableDocumentList extends React.Component {
  static propTypes = {
    limit: PropTypes.number,
  };

  static defaultProps = {
    limit: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      filterText: '',
      documents: null,
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  componentWillMount() {
    const apiEndpoint = 'https://right2water.cdn.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api
        .query(Prismic.Predicates.at('document.type', 'document'), {
          orderings: '[my.document.publication_date desc]',
          pageSize: 100,
        })
        .then(response => {
          if (response) {
            this.setState({ documents: response.results });
          }
        });
    });
  }

  handleFilterTextChange(filterText) {
    this.setState({
      filterText,
    });
  }

  render() {
    if (this.state.documents) {
      return (
        <div>
          <div className={s.content}>
            <div className={s.container}>
              <SearchBar
                filterText={this.state.filterText}
                onFilterTextChange={this.handleFilterTextChange}
              />
              <Documents
                documents={this.state.documents}
                filterText={this.state.filterText}
                limit={this.props.limit && this.props.limit}
              />
              {this.props.limit && (
                <div className={s.actions}>
                  <Link className={s.moreLink} to="/documents">
                    View
                  </Link>{' '}
                  all documents
                </div>
              )}
            </div>
          </div>
          <div className={s.WaveLightBottom}>
            <WaveLightBottom />
          </div>
        </div>
      );
    }
    return <Loader />;
  }
}

export default withStyles(s)(FilterableDocumentList);
