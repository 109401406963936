// In src/NewsTeaser.js

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NewsTeaser.scss';
import Link from '../Link';
import PublicationDate from '../PublicationDate';

class NewsTeaser extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    date: PropTypes.string,
    image_url: PropTypes.string,
  };

  static defaultProps = {
    date: null,
    image_url: null,
  };

  render() {
    return (
      <article className={s.newsTeaser}>
        {this.props.image_url && (
          <figure className={s.newsTeaser__figure}>
            <img alt="cover" src={this.props.image_url} />
          </figure>
        )}
        <div>
          {this.props.date && (
            <footer className={s.newsTeaser__footer}>
              <PublicationDate date={this.props.date} />
            </footer>
          )}
          <h1 className={s.newsTeaser__title}>
            <Link to={this.props.destination}>{this.props.title}</Link>
          </h1>
        </div>
      </article>
    );
  }
}

export default withStyles(s)(NewsTeaser);
