import React from 'react';
import { TweenMax, Power1 } from 'gsap';

class WaveLightBottom extends React.Component {
  constructor() {
    super();
    this.startPath = null;
    this.svgTween = null;
  }

  componentDidMount() {
    this.svgTween = TweenMax.to(this.startPath, 6, {
      attr: {
        d:
          'M1440,0 L1440,44.5742188 C1335.76647,89.0423156 1292.44989,69.3632812 1239.82857,69.3632812 C1187.90625,69.3632813 1125.54688,98.9648437 1054.01367,91.2382812 C982.480469,83.5117188 891.841148,69.3632812 809.460938,69.3632812 C710.820419,69.3632812 658.933594,77.3554688 573.089844,77.3554688 C515.860677,77.3554687 415.461871,63.1888021 271.893427,34.8554688 C160.250934,11.6090627 69.6197917,14.8486461 0,44.5742188 L0,0 L1440,0 Z',
      },
      ease: Power1.easeInOut,
      repeat: -1,
      yoyo: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 110">
          <path
            ref={path => {
              this.startPath = path;
            }}
            fill="#DAE6F2"
            d="M1440,0 L1440,68.0877712 C1335.76647,112.555868 1292.44989,109.971992 1239.82857,109.971992 C1187.90625,109.971992 1125.54688,84.3710938 1054.01367,76.6445313 C982.480469,68.9179688 891.841148,80.0703125 809.460938,80.0703125 C710.820419,80.0703125 658.933594,49.6640625 573.089844,49.6640625 C487.246094,49.6640625 416.834437,10.5203463 271.893427,10.5203463 C138.926207,10.5203463 104.429687,23.411641 0,68 L0,0 L1440,0 Z"
          />
        </svg>
      </React.Fragment>
    );
  }
}

export default WaveLightBottom;
