exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2hahX{min-height:54px;padding-left:72px;position:relative}@media screen and (min-width:768px){._2hahX{min-height:130px;padding-left:148px}}._131cl{font-size:14.24px;font-size:.89rem}@media screen and (min-width:768px){._131cl{font-size:1rem}}._131cl a{text-decoration:none}._1Gjbs{border-radius:50%;-webkit-box-shadow:4px 4px 0 #dae6f2;box-shadow:4px 4px 0 #dae6f2;display:block;line-height:0;margin:0;max-width:54px;height:auto;overflow:hidden;left:0;position:absolute;top:0}@media screen and (min-width:768px){._1Gjbs{max-width:130px}}._1Gjbs img{margin-left:50%;max-width:54px;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%)}@media screen and (min-width:768px){._1Gjbs img{max-width:none}}._1KxkH{color:#888;font-size:14.24px;font-size:.89rem}", ""]);

// exports
exports.locals = {
	"newsTeaser": "_2hahX",
	"newsTeaser__title": "_131cl",
	"newsTeaser__figure": "_1Gjbs",
	"newsTeaser__footer": "_1KxkH"
};