import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './AboutSection.scss';
import Link from '../Link';
import icnEuro from './icn-euro.svg';
import icnTap from './icn-tap.svg';
import icnWorld from './icn-world.svg';
import icnFb from './icn-facebook.svg';
import icnTwitter from './icn-twitter.svg';
import glassImage from './glass.svg';
import drinkwater from './drinkwater.jpg';
import dropImage from './drop.svg';
import proposals from './proposals.md';
import WaveDarkTop from './WaveDarkTop';

const getElemDistance = elem => {
  let location = 0;
  let el = elem;
  if (el.offsetParent) {
    do {
      location += el.offsetTop;
      el = el.offsetParent;
    } while (el);
  }
  return location >= 0 ? location : 0;
};

class AboutSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cursorPosition: [],
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const glass = document.getElementById('glass');
    const cursor = document.getElementById('glassCursor');
    const line = document.getElementById('glassLine');
    const lineState = document.getElementById('glassLineState');
    const top = window.pageYOffset; // From page
    const cursorPageTop = getElemDistance(cursor); // From page
    const glassPageTop = getElemDistance(glass); // From page
    const topShift = window.innerHeight / 3;

    const cursorTop = top - glassPageTop + 117 + topShift;
    const cursorStyle = {
      top: cursorTop,
    };

    const cursorStyle2 = {
      top: cursorTop,
      visibility: 'hidden',
    };

    const cursorStyle3 = {
      top: '117px',
    };

    let positionMax = null; // Should be page top position of last data-step item
    let lineStateMaxHeight = '100%'; // Should be page top position of last data-step item

    // for (const element of document.querySelectorAll('[data-step]')) {
    document.querySelectorAll('[data-step]').forEach(element => {
      const step = element.getAttribute('data-step');
      const distanceTop = getElemDistance(element); // From page
      const marker = document.querySelectorAll(`[data-step-marker=${step}]`)[0];
      const markerTop = distanceTop - glassPageTop;

      if (cursorPageTop > distanceTop && step !== 'pending') {
        marker.setAttribute(
          'style',
          `background-color: #5cb6e4; box-shadow: none; top:${markerTop}px; transform: scale(1.5) translateX(-50%);font-size: 14px;opacity:1`,
        );
      } else if (cursorPageTop > distanceTop && step === 'pending') {
        marker.setAttribute(
          'style',
          `background-color: #5cb6e4;box-shadow: none;top:${markerTop}px; transform: scale(.8) translateX(-50%);opacity:1`,
        );
      } else {
        marker.setAttribute('style', `top:${markerTop}px; opacity:1`);
      }

      if (step === 'pending') {
        // Set line total height
        line.setAttribute('style', `height:${markerTop - 40}px; top:40px;`);
        positionMax = distanceTop;
        lineStateMaxHeight = markerTop - 40;
      }
    });

    // Set line current state
    lineState.setAttribute(
      'style',
      `height:${cursorTop - 40}px; top:40px;max-height:${lineStateMaxHeight}px`,
    );

    // Set cursor (drop) position
    if (cursorPageTop > positionMax) {
      this.setState({ cursorPosition: cursorStyle2 });
    } else if (glassPageTop < top + topShift) {
      this.setState({ cursorPosition: cursorStyle });
    } else {
      this.setState({ cursorPosition: cursorStyle3 });
    }
  }

  render() {
    const { title, html } = proposals;
    return (
      <div className={s.root}>
        <section className={s.about}>
          <section className={s.about__status}>
            <div className={s.lConstrain}>
              <svg
                width="500"
                height="120"
                viewBox="0 0 500 120"
                className={s.cuvedText}
              >
                <path
                  id="curve"
                  d="M34,98 C51.126302,80.8320312 85.241537,72.7675781 136.345704,72.7675781 C213.001954,72.7675781 212.65625,35 312.683594,35 C412.710938,35 394.5625,90.4882813 556.259766,94.5234375 C664.057943,97.2135417 732.496094,98 765,98 L34,98 Z"
                />
                <text x="25">
                  <textPath
                    href="#curve"
                    aria-label="1,884,790 europeans signed on 1,000,000 needed"
                  >
                    1,884,790 europeans signed on 1,000,000 needed
                  </textPath>
                </text>
              </svg>

              <div className={s.about__timeline}>
                <div className={s.about__timelineGlass}>
                  <img src={glassImage} alt="" className={s.glass} id="glass" />
                  <img
                    src={dropImage}
                    alt=""
                    id="glassCursor"
                    className={s.glassCursor}
                    style={this.state.cursorPosition}
                  />
                  <div id="glassLine" className={s.glassLine} />
                  <div id="glassLineState" className={s.glassLineState} />
                  <div data-step-marker="done-1" className={s.stepMarker}>
                    ✓
                  </div>
                  <div data-step-marker="done-2" className={s.stepMarker}>
                    ✓
                  </div>
                  <div data-step-marker="pending" className={s.stepMarker} />
                </div>
                <h1>
                  A big thank you to all petitioners! We achieved the first part
                  of the job.
                </h1>
                <div className={s.socialBoxes}>
                  <div className={s.socialBox}>
                    <span>+ 4 700 followers</span>
                    <a href="https://twitter.com/right2water">
                      <img src={icnTwitter} alt="" />Twitter
                    </a>
                  </div>
                  <div className={s.socialBox}>
                    <span>+ 10 000 likes</span>
                    <a href="https://www.facebook.com/right2water-259222457451652/">
                      <img src={icnFb} alt="" /> Facebook
                    </a>
                  </div>
                </div>
                <div className={s.about__steps}>
                  <div data-step="done-1">
                    <h3>
                      Campaigning and mobilization after collecting almost 2
                      million signatures.
                    </h3>
                  </div>
                  <div data-step="done-2">
                    <h3>
                      The European Commission has partly addressed our demands
                      in the proposed Drinking Water Directive! This is great
                      news.
                    </h3>
                  </div>
                  <div data-step="pending">
                    <h3>Now what ? There is still work to be done</h3>
                    <p>
                      We invite the European Commission to propose legislation
                      implementing the human right to water and sanitation as
                      recognised by the United Nations.
                    </p>
                    <p>
                      Follow the blog to keep informed about the{' '}
                      <Link to="/news">latest advancements</Link>.
                    </p>
                  </div>
                </div>
                <svg
                  width="182"
                  height="241"
                  viewBox="0 0 182 241"
                  className={s.drinkwater}
                >
                  <defs>
                    <mask id="drinkwaterMask">
                      <path
                        fill="#ffffff"
                        d="M112.649434,0.802927618 C88.0681117,-2.38808837 3.13867187,60.9082031 0.431640625,139.578125 C-2.27539063,218.248047 47.0458789,235.435547 79.8476562,240.117188 C112.649434,244.798828 175.053086,225.924111 181.220703,157.480469 C187.38832,89.0368265 137.230755,3.99394361 112.649434,0.802927618 Z"
                      />
                    </mask>
                  </defs>
                  <path
                    fill="#FFCA1E"
                    mask="url(#drinkwaterMask)"
                    fillRule="evenodd"
                    d="M76.8144531,237.560547 C94.9365329,246.98711 153.667676,278.839597 168.355608,213.028617 C183.04354,147.217638 141.134766,134.275391 113.824219,134.275391 C86.5136719,134.275391 36.3886719,161.798828 39.9316406,186.082031 C43.4746094,210.365234 58.6923733,228.133983 76.8144531,237.560547 Z"
                    transform="translate(-30 -130)"
                  />
                  <image
                    mask="url(#drinkwaterMask)"
                    xlinkHref={drinkwater}
                    preserveAspectRatio="xMidYMid slice"
                    width="182"
                    height="240"
                  />
                </svg>
              </div>
              <div className={s.about__goalsUrge}>
                <div className={s.about__goals}>
                  <h3>Our goals</h3>
                  <ul className={s.goalsList}>
                    <li>
                      <img src={icnEuro} alt="" />
                      No liberalisation of water services
                    </li>
                    <li>
                      <img src={icnTap} alt="" />
                      Guaranteed water and sanitation for all in Europe
                    </li>
                    <li>
                      <img src={icnWorld} alt="" />
                      Universal (Global) access to water and sanitation
                    </li>
                  </ul>
                </div>
                <div className={s.about__urge}>
                  <h3>We urge that:</h3>
                  <ul>
                    <li>
                      The EU institutions and Member States be obliged to ensure
                      that all inhabitants enjoy the right to water and
                      sanitation.
                    </li>
                    <li>
                      Water supply and management of water resources not be
                      subject to ‘internal market rules’ and that water services
                      are excluded from liberalisation.
                    </li>
                    <li>
                      The EU increases its efforts to achieve universal access
                      to water and sanitation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <WaveDarkTop />
          <section className={s.about__proposals}>
            <div className={s.lConstrain}>
              <h1 className={s.about__proposalsTitle}>{title}</h1>
              <div
                className={s.about__proposalsText}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </section>
        </section>
      </div>
    );
  }
}

export default withStyles(s)(AboutSection);
