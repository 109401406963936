/**
 * Get current path and set active class
 * @todo use `react-router-dom` to automated this
 * @see https://github.com/kriasoft/react-starter-kit/issues/682
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NavigationLink.scss';
import Link from '../Link';
import history from '../../history';

class NavigationLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }
  componentDidMount() {
    this.onPath();
  }
  componentWillReceiveProps() {
    this.onPath();
  }
  onPath() {
    if (history.location.pathname.includes(this.props.to)) {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
    }
  }
  render() {
    return (
      <Link
        to={this.props.to}
        className={cx(s.link, this.state.isActive && s.active)}
      >
        {this.props.title}
      </Link>
    );
  }
}

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(s)(NavigationLink);
