// In src/Documents.js

import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Documents.scss';
import Document from '../Document';

class Documents extends React.Component {
  static propTypes = {
    documents: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    filterText: PropTypes.string,
    limit: PropTypes.number,
  };

  static defaultProps = {
    filterText: null,
    limit: null,
  };

  render() {
    const filterTextVal = this.props.filterText;
    const rows = [];

    this.props.documents.forEach(document => {
      if (
        document.data.title[0].text
          .toLowerCase()
          .indexOf(filterTextVal.toLowerCase()) === -1
      ) {
        return;
      }
      rows.push(
        <li key={document.id}>
          <Document
            title={RichText.asText(document.data.title)}
            id={document.id}
            date={document.data.publication_date}
            link={document.data.link}
            body={RichText.asText(document.data.body)}
          />
        </li>,
      );
    });

    if (rows.length > 0 && this.props.limit) {
      return <ul className={s.documents}>{rows.slice(0, this.props.limit)}</ul>;
    }
    if (rows.length > 0) {
      return <ul className={s.documents}>{rows}</ul>;
    }
    return <p>No result</p>;
  }
}

export default withStyles(s)(Documents);
