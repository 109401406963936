// In src/Document.js

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Document.scss';
import PublicationDate from '../PublicationDate';

const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

class Document extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    date: PropTypes.string,
    link: PropTypes.shape({
      link_type: PropTypes.string,
      url: PropTypes.string,
    }),
  };

  static defaultProps = {
    date: null,
    link: null,
  };

  getSize = link => {
    if (link.link_type === 'Media') {
      return ` [${formatBytes(link.size)}]`;
    }
    return '';
  };

  render() {
    const size = this.getSize(this.props.link);
    if (this.props.link.url) {
      return (
        <article className={s.document} data-wio-id={this.props.id}>
          <h1 className={s.document__title}>
            <a href={this.props.link.url} className={s.external}>
              {this.props.title}
              {size}
            </a>
          </h1>
          {this.props.body && ',\u00A0'}
          {this.props.body}
          {this.props.date && ',\u00A0'}
          {this.props.date && <PublicationDate date={this.props.date} />}
        </article>
      );
    }
    return null;
  }
}

export default withStyles(s)(Document);
