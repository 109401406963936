var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2c0w9{color:#a3b8cc}._2c0w9,._2OA52{font-size:16px;font-size:1rem}._2OA52{display:inline;font-weight:700}._2OA52 a{color:#175699;text-decoration:none}._2OA52 a:focus,._2OA52 a:hover{color:#5cb6e4}._25O_T{position:relative}._25O_T:after{background:url(" + escape(require("./icn-external-blue.svg")) + ") no-repeat 50%;content:\"\";display:inline-block;height:7px;margin-left:.2em;-webkit-transform:translate(-2px,2px);-ms-transform:translate(-2px,2px);transform:translate(-2px,2px);vertical-align:text-top;width:7px}._25O_T:hover:after{-webkit-transform:none;-ms-transform:none;transform:none}", ""]);

// exports
exports.locals = {
	"document": "_2c0w9",
	"document__title": "_2OA52",
	"external": "_25O_T"
};