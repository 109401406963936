exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".aIXWk{display:block;padding:.1em 0;text-decoration:none;font-size:14.24px;font-size:.89rem}.aIXWk,.aIXWk:active,.aIXWk:visited{color:#175699}.aIXWk._2lJqR,.aIXWk:hover{color:#5cb6e4}", ""]);

// exports
exports.locals = {
	"link": "aIXWk",
	"active": "_2lJqR"
};