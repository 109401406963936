import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SearchBar.scss';

class SearchBar extends React.Component {
  static propTypes = {
    filterText: PropTypes.string,
    onFilterTextChange: PropTypes.func,
  };

  static defaultProps = {
    filterText: null,
    onFilterTextChange: null,
  };

  constructor(props) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  handleFilterTextChange(e) {
    this.props.onFilterTextChange(e.target.value);
  }

  render() {
    return (
      <form className={s.searchForm}>
        <input
          className={s.searchForm__input}
          type="text"
          placeholder="Find documents…"
          value={this.props.filterText}
          onChange={this.handleFilterTextChange}
        />
      </form>
    );
  }
}

export default withStyles(s)(SearchBar);
