import React from 'react';
import { TweenMax, Power1 } from 'gsap';

class WaveDarkTop extends React.Component {
  constructor() {
    super();
    this.startPath = null;
    this.svgTween = null;
  }

  componentDidMount() {
    this.svgTween = TweenMax.to(this.startPath, 4, {
      attr: {
        d:
          'M0,110 L0,35.7421875 C105.863281,25.2109375 147.198993,12.6336387 199.820312,12.6336387 C275.39319,12.6336387 312.675551,32.1591238 394.191406,35.7421875 C475.707262,39.3252512 517.803383,19.984375 600.183594,19.984375 C698.824112,19.984375 727.768118,68.734375 845.867188,68.734375 C963.966257,68.734375 1024.44961,93.0507812 1169.39062,93.0507812 C1302.35785,93.0507812 1335.57031,94.4360153 1440,49.8476562 L1440,110 L0,110 Z',
      },
      ease: Power1.easeInOut,
      repeat: -1,
      yoyo: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 110">
          <path
            ref={path => {
              this.startPath = path;
            }}
            fill="#0A2440"
            d="M0,110 L0,41.9122288 C104.233527,-2.55586813 147.550107,0.0280081365 200.171427,0.0280081365 C275.744304,0.0280081365 314.208218,38.1536299 395.724074,41.7366936 C477.239929,45.3197573 520.377509,12.6336387 602.75772,12.6336387 C701.398239,12.6336387 727.763579,61.3522302 845.862649,61.3522302 C963.961719,61.3522302 1023.16556,99.4796537 1168.10657,99.4796537 C1301.07379,99.4796537 1335.57031,86.588359 1440,42 L1440,110 L0,110 Z"
          />
        </svg>
      </React.Fragment>
    );
  }
}

export default WaveDarkTop;
