var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cfAl{background:#dae6f2;margin-top:200px;position:relative}._1cfAl:before{background:url(" + escape(require("./wave_light_top.svg")) + ") repeat-x top;content:\"\";display:block;height:200px;left:0;position:absolute;top:-200px;width:100%}._1cfAl svg{display:block}._3ewre{padding:0 16px 48px;padding:0 1rem 3rem}@media screen and (min-width:768px){._3ewre{padding:3rem 1rem;margin-left:auto;margin-right:auto;max-width:580px}}._1DNej{color:#333;font-weight:600;position:relative;text-decoration:none;-webkit-transition:color .4s;-o-transition:color .4s;transition:color .4s}._1DNej:before{background:-webkit-gradient(linear,left bottom,left top,color-stop(100%,#5cb6e4),color-stop(0,#5cb6e4)) no-repeat;background:-webkit-linear-gradient(bottom,#5cb6e4 100%,#5cb6e4 0) no-repeat;background:-o-linear-gradient(bottom,#5cb6e4 100%,#5cb6e4 0) no-repeat;background:linear-gradient(0deg,#5cb6e4 100%,#5cb6e4 0) no-repeat;background-size:100% 6px;background-position:bottom;opacity:.7;content:\"\";bottom:0;left:0;right:0;top:0;position:absolute;-webkit-transition:all .6s cubic-bezier(.19,1,.22,1);-o-transition:all .6s cubic-bezier(.19,1,.22,1);transition:all .6s cubic-bezier(.19,1,.22,1)}._1DNej:hover{color:#5cb6e4}._1DNej:hover:before{background-size:100% 1px;opacity:1;z-index:0}._2nGso{text-align:center}._3DJ3s svg{display:block}", ""]);

// exports
exports.locals = {
	"content": "_1cfAl",
	"container": "_3ewre",
	"moreLink": "_1DNej",
	"actions": "_2nGso",
	"WaveLightBottom": "_3DJ3s"
};