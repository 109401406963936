exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EMGGC{display:block;height:64px;margin:0 auto;position:relative;width:100px;-webkit-transform:translateY(50px);-ms-transform:translateY(50px);transform:translateY(50px)}.EMGGC div{border-radius:50%;border:1px solid #175699;position:absolute;opacity:0;-webkit-animation:EMGGC 1.5s cubic-bezier(0,.2,.8,1) infinite;animation:EMGGC 1.5s cubic-bezier(0,.2,.8,1) infinite;-webkit-animation-delay:.4s;animation-delay:.4s}.EMGGC span{display:block;margin:0 auto;border-radius:50%;background:red;background:#5cb6e4;height:4px;width:4px;-webkit-animation:_21ZKD 1.5s cubic-bezier(0,0,1,.55) infinite;animation:_21ZKD 1.5s cubic-bezier(0,0,1,.55) infinite}@-webkit-keyframes _21ZKD{0%{opacity:0;-webkit-transform:translateY(-18px);transform:translateY(-18px)}28%{opacity:1;-webkit-transform:translateY(26px);transform:translateY(26px)}29%{opacity:0;-webkit-transform:translateY(26px);transform:translateY(26px)}to{opacity:0;-webkit-transform:none;transform:none}}@keyframes _21ZKD{0%{opacity:0;-webkit-transform:translateY(-18px);transform:translateY(-18px)}28%{opacity:1;-webkit-transform:translateY(26px);transform:translateY(26px)}29%{opacity:0;-webkit-transform:translateY(26px);transform:translateY(26px)}to{opacity:0;-webkit-transform:none;transform:none}}@-webkit-keyframes EMGGC{0%{top:26px;left:48px;width:0;height:0;opacity:1}to{top:-2px;left:-2px;width:100px;height:58px;opacity:0}}@keyframes EMGGC{0%{top:26px;left:48px;width:0;height:0;opacity:1}to{top:-2px;left:-2px;width:100px;height:58px;opacity:0}}", ""]);

// exports
exports.locals = {
	"ldsRipple": "EMGGC",
	"drop": "_21ZKD"
};