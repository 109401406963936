// In src/BlogTeaser.js

import React from 'react';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './BlogTeaser.scss';
import NewsTeaser from '../NewsTeaser';
import SubscribeBlock from '../../components/SubscribeBlock';
import Link from '../Link';
import Loader from '../Loader';

class BlogTeaser extends React.Component {
  state = {
    doc: null,
  };

  componentWillMount() {
    const apiEndpoint = 'https://right2water.cdn.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
      api
        .query(Prismic.Predicates.at('document.type', 'news'), {
          orderings: '[my.news.publication_date desc]',
          pageSize: 2,
        })
        .then(response => {
          if (response) {
            this.setState({ doc: response.results });
          }
        });
    });
  }

  getImageUrl = image => {
    if (typeof image.teaser !== 'undefined') {
      return image.teaser.url;
    }
    return image.url;
  };

  render() {
    if (this.state.doc) {
      const newsItems = this.state.doc.map(news => (
        <li key={news.uid} className={s.newsList__item}>
          <NewsTeaser
            title={RichText.asText(news.data.title)}
            destination={`/news/${news.uid}`}
            image_url={news.data.image && this.getImageUrl(news.data.image)}
            date={news.data.publication_date}
          />
        </li>
      ));

      return (
        <section className={s.container}>
          <h1 className={s.blogTeaser__title}>The latest advancement</h1>
          <ul className={s.newsList}>{newsItems}</ul>
          <div className={s.actions}>
            <Link className={s.moreLink} to="/news">
              View
            </Link>{' '}
            previous news
          </div>
          <SubscribeBlock />
        </section>
      );
    }
    return <Loader />;
  }
}

export default withStyles(s)(BlogTeaser);
