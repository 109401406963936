exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._39_tD{padding:16px;padding:1rem}@media screen and (min-width:768px){._39_tD{margin-left:auto;margin-right:auto;max-width:580px;padding:3rem 1rem}}._37Gsc{margin-bottom:48px;margin-bottom:3rem;margin-top:0;text-align:center}@media screen and (min-width:768px){._37Gsc{margin-top:1rem}}._39Udt{list-style:none;margin:0;padding-left:0;position:relative;z-index:1}._13PmD{margin-bottom:24px;margin-bottom:1.5rem}._29dbm{text-align:center}._3Z3BR{color:#333;font-weight:600;position:relative;text-decoration:none;-webkit-transition:color .4s;-o-transition:color .4s;transition:color .4s}._3Z3BR:before{background:-webkit-gradient(linear,left bottom,left top,color-stop(100%,#5cb6e4),color-stop(0,#5cb6e4)) no-repeat;background:-webkit-linear-gradient(bottom,#5cb6e4 100%,#5cb6e4 0) no-repeat;background:-o-linear-gradient(bottom,#5cb6e4 100%,#5cb6e4 0) no-repeat;background:linear-gradient(0deg,#5cb6e4 100%,#5cb6e4 0) no-repeat;background-size:100% 6px;background-position:bottom;opacity:.7;content:\"\";bottom:0;left:0;right:0;top:0;position:absolute;-webkit-transition:all .6s cubic-bezier(.19,1,.22,1);-o-transition:all .6s cubic-bezier(.19,1,.22,1);transition:all .6s cubic-bezier(.19,1,.22,1)}._3Z3BR:hover{color:var(--color-secondary)}._3Z3BR:hover:before{background-size:100% 1px;opacity:1;z-index:0}", ""]);

// exports
exports.locals = {
	"container": "_39_tD",
	"blogTeaser__title": "_37Gsc",
	"newsList": "_39Udt",
	"newsList__item": "_13PmD",
	"actions": "_29dbm",
	"moreLink": "_3Z3BR"
};